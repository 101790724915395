import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import { Spinner } from 'react-bootstrap';
import { TYPE_SET_LANGUAGE } from '../../store/reducer';

/**
 *
 * @param {authRequired} props
 *
 */
const AuthStateProvider = (props) => {
  const { authRequired } = props;
  const [ready, setReady] = useState(false);
  const { loggedIn, language } = useSelector((state) => state);
  const dispatch = useDispatch();
  let currentURL = localStorage.getItem('qrUrl');

  useEffect(() => {
    if (authRequired && !loggedIn) {
      navigate('/');
    }
    if (!authRequired && loggedIn) {
      if (localStorage.getItem('qrUrl') !== null) {
        if (currentURL.includes('when-time-began')) {
          navigate('/when-time-began');
        } else if (currentURL.includes('rise-of-the-prophets')) {
          navigate('/rise-of-the-prophets');
        } else if (currentURL.includes('prophets-priests-and-kings')) {
          navigate('/prophets-priests-and-kings');
        } else if (currentURL.includes('the-miracle-man')) {
          navigate('/the-miracle-man');
        } else if (currentURL.includes('savior-of-the-world')) {
          navigate('/savior-of-the-world');
        } else if (currentURL.includes('way-of-the-master')) {
          navigate('/way-of-the-master');
        } else {
          navigate('/the-greatest-battle');
        }
      } else {
        navigate('/profile');
      }
    }
    if ((authRequired && loggedIn) || (!authRequired && !loggedIn)) {
      setReady(true);
    }
  }, [loggedIn, authRequired]);

  useEffect(() => {
    const currentPath = props.location.pathname;
    if (!authRequired) {
      return;
    }
    if (currentPath.split('/').includes('es') && language === 'eng') {
      dispatch({ type: TYPE_SET_LANGUAGE, payload: 'esp' });
    } else if (!currentPath.split('/').includes('es') && language === 'esp') {
      dispatch({ type: TYPE_SET_LANGUAGE, payload: 'eng' });
    }
  }, []);

  return (
    <div id="outer-wrapper" className={`${props.className}`}>
      {ready ? (
        props.children
      ) : (
        <div
          style={{
            position: 'absolute',
            top: '40%',
            left: '50%',
          }}
        >
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
        </div>
      )}
    </div>
  );
};
export default AuthStateProvider;
